import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_font_awesome_icon, {
      icon: "lock",
      size: "lg"
    }),
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t("unauthorized.title")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("unauthorized.message")), 1),
    (_ctx.redirect)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.redirect,
          class: "btn btn-primary",
          role: "button"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("shared.back-previous-page")), 1)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: { name: 'StudentHome' },
          class: "btn btn-primary",
          role: "button"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("shared.go-home-page")), 1)
          ]),
          _: 1
        }))
  ]))
}